import React, { useState, useEffect } from "react";
import ToolsData from "../data/Tools.json";

const QuijoteLorem = () => {
  let toolsData = ToolsData;
  const [data, setData] = useState('')

  useEffect( () => {
    setData(toolsData.find( (el) => el.slug === "quijote_lorem"))
  }, [])

  return (
    <section className="main-section tool-page">
      <span className="page-header-btn">Quijote Lorem Generator</span>
      <article className="card-body">
        <img src="/assets/img/projects/quijote_lorem_750_473.jpg" alt="" className="card-image" />
        <div className="card-text">
          <h2 className="card-header m-top-1s">{data.name}</h2>
          <p className="card-name-date m-top-1">
            {data.long_desc}
          </p>
          <small>
            {/* {props.tags.map((tag, index) => (
              <span key={index} className="card-tag-name">
                {tag.name}
              </span>
            ))} */}
          </small>
          <p className="card-text-content m-top-1">
            {/* {props.description?.map((res) => (
              <>{res[props.descriptionLanguage]}</>
            ))} */}
          </p>
          {/* <div className="card-btn">
            <a
              className="card-btn-link"
              alt="#"
              onClick={() => props.handleButton(projectObject)}
            >
              <span className="card-btn-txt">{dictionary.card_btn}</span>
              <img
                src="https://www.svgrepo.com/show/121107/arrow.svg"
                alt="arrow"
                className="button-icon"
              />
            </a>
          </div> */}
          <a href={data.link} target="_blank" >Go</a>
        </div>
      </article>
    </section>
  );
};

export default QuijoteLorem;
