import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import NavMenu from './NavMenu'

const Layout = () => {
  return (
    <main className='App'>
    {/* OUTLET -> represents all the children of the Layout component */}
      <NavMenu />
      <Outlet />
      <Footer />
    </main>
  )
}

export default Layout