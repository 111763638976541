import React from 'react'
import DarkModeIcon from './DarkModeIcon'
import { Link } from 'react-router-dom'
import FlagIcon from './FlagIcon'

const LogoMenu = () => {
  return (
    <section className='logo-menu'>
      <ul>
        <li id='logo'>
          <Link to="/"><span className='logo'><b>JJM</b> Dev</span></Link>
        </li>
        <li className='right-side'>
          <FlagIcon />
          <Link to="/" className="switch-handle">
            <DarkModeIcon />
          </Link>
        </li>
      </ul>
    </section>
  )
}

export default LogoMenu