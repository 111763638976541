import React from 'react'
import { UseLanguageContext } from '../context/Languaje-context'
import Dictionary from "../data/Dictionary.json";

const FlagIcon = () => {
  const {
    global: {
      globalValue,
      setDictionary
    },
    language: {
      selectedLanguage, setSelectedLanguage
    }
  } = UseLanguageContext()

  const toogleClass = (el) => {
    let allIcons = document.querySelectorAll('.flag-icon')
    for(let icon of allIcons){
      icon.classList.add('flag-icon-transparency')
      icon.classList.add('hide')
    }
    let element = document.querySelector(`#lg-${el}`)
    console.log(element)
    element.classList.remove('hide')
  }

  const selectLanguage = (e) => {
    if(e.target.name === 'EN'){
      setDictionary(Dictionary.en)
    }else if(e.target.name === 'FR'){
      setDictionary(Dictionary.fr)
    }else{
      setDictionary(Dictionary.es)
    }
    let lower = e.target.name
    lower = lower.toLowerCase()
    setSelectedLanguage(lower)
    toogleClass(lower)
  }

  return (
    <section className='lateral-btn'>
      <img id="lg-en" src='assets/img/flags/en-48.png' name="EN" className='flag-icon' onClick={selectLanguage}/>
      <img id="lg-fr" src='assets/img/flags/fr-48.png' name="FR" className='flag-icon' onClick={selectLanguage}/>
      <img id="lg-es" src='assets/img/flags/es-48.png' name="ES" className='flag-icon' onClick={selectLanguage}/>
    </section>
  )
}

export default FlagIcon