import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { UseLanguageContext } from "../context/Languaje-context";

const Home = (props) => {
  const [bg, setBg] = useState("assets/img/Illustration_light.svg");
  const {
    language: { selectedLanguage },
    mode: { mode },
  } = UseLanguageContext();

  useEffect(() => {
    console.log("MODE: ", mode);
    if (props.mode != null) {
      setBg(`assets/img/Illustration_${mode}.svg`);
    }
  }, [mode]);

  return (
    <>
      <div className="homepage">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: `url(${bg})`,
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Juan José Mora</h3>
            <p className="job">
              <b>Desarrollador Web & Formador - {selectedLanguage}</b>
            </p>
            <p className="job-text">
              Desarrollador Web WordPress, Symfony y React JS
            </p>
            {/* END JOB */}
            {/* <Social /> */}
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
