import { React, useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from "react-google-recaptcha"

const Mailer = (props) => {
  const [result, setResult] = useState(null)
  const [active, setIsActive] = useState(false)
  const captcha = useRef(null)
  const [validCaptcha, setValidCaptcha] = useState(false)

  const sendEmail = (event) => {
    event.preventDefault()
    if(validCaptcha === true){
      emailjs.sendForm(
        'jjmdev_xyz_contact_form',
        'template_vgu2ppt',
        event.target,
        'user_apfJlgYJWHhKTzbqRZrgl'
        )
        .then(response => {
          setResult(response.status)
          setIsActive(false)
        })
        .catch(error => {
          setResult(400)
          console.log('ERROR :', error)
        })
    }else{
      setResult(true)
    }
  }
  const handleChange = (event) => {
    setIsActive(true)
  }

  const onValidateReCaptcha = () => {
    console.log('Captcha Validated')
    console.log(captcha.current.getValue())
    const token = captcha.current.getValue()
    if(token){
      console.log('User is not a Robot')
      setValidCaptcha(true)
    }
  }

  return (
    <section className='form-section'>
      <form className='form-contact' onSubmit={sendEmail}>
        <div className='form-field'>
          <label>{props.form.name}</label>
          <input type="text" name='user_name' onChange={handleChange} />
        </div>
        <div className='form-field'>
          <label>{props.form.email}</label>
          <input type="email" name='user_email'onChange={handleChange} />
        </div>
        <div className='form-field'>
          <label>{props.form.message}</label>
          <textarea name='user_message' cols="30" rows="10" onChange={handleChange} ></textarea>
        </div>
        <div className='recaptcha'>
          <ReCAPTCHA 
            ref={captcha}
            sitekey={"6LcAbvQjAAAAALuQrJfvG3mdF-kVaBEkZo32zzWK"}
            onChange={onValidateReCaptcha}
            />
        </div>
        <button>{props.form.button}</button>
      </form>
      <p className='form-message'>
        {result === 200 ? 'The form was sent correctly, thank you' : ''}
      </p>
      <p className='form-error-message'>
        {result === 400 ? "An error ocurred, your form wasn't sent correctly" : ''}
      </p>
      <p className='form-error-message'>
        {result === true ? 'Please validate the captcha' : ''}
      </p>
    </section>
  )
}

export default Mailer