import React from 'react'
import ComingSoon from '../components/ComingSoon'

const AiChat = () => {
  return (
    <section className='main-section ai-chat'>
      <span className='page-header-btn'>
        AI Chat
      </span>
      <ComingSoon />
    </section>
  )
}

export default AiChat