import React from 'react'
import { Link } from 'react-router-dom'
import { UseLanguageContext } from '../context/Languaje-context'

const Footer = () => {
  const {
    global: {
      dictionary
    }
  } = UseLanguageContext()

  return (
    <nav className='footer'>
      <Link to="/">
        <span className='logo-footer'><b>JJM</b> Dev</span>
      </Link>
      <ul>
        {/* <li>
          <Link className='menu-icon-wrapper' to="/">
            <img src='assets/img/home-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">Home</span>
          </Link>
        </li> */}
        <li>
          <Link className='menu-icon-wrapper' to="/about">
            <img src='assets/img/user-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">{dictionary.footer.about}</span>
          </Link>
        </li>
        <li>
          <Link className='menu-icon-wrapper' to="/portfolio">
            <img src='assets/img/box-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">{dictionary.footer.portfolio}</span>
          </Link>
        </li>
        <li>
          <Link className='menu-icon-wrapper' to="/learn">
            <img src='assets/img/file-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">{dictionary.footer.learn}</span>
          </Link>
        </li>
        <li>
          <Link className='menu-icon-wrapper' to="/tools">
            <img src='assets/img/tools2-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">{dictionary.footer.tools}</span>
          </Link>
        </li>
        <li>
          <Link className='menu-icon-wrapper' to="/contact">
            <img src='assets/img/email-svgrepo-com.svg' alt='' className='menu-icon' />
            <span className="link-name">{dictionary.footer.contact}</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Footer