import React from "react";
import Card from "./Card";
import LearnData from "../data/LearnData.json";

const Learn = (props) => {
  let learnData = LearnData;

  return (
    <section className="main-section learn">
      <span className="page-header-btn">{props.dictionary.pages.learn}</span>
      <div className="coming-soon">
        <h2>Coming Soon...</h2>
      </div>
      <div className="cards-wrapper">
        {learnData && learnData.length === 0 ? (
          <div className="loader">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          ""
        )}
        {learnData.map((project, key) => {
          return (
            <Card
              image={project.image}
              date={project.date}
              title={project.title}
              tags={project.tags}
              key={key}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Learn;
