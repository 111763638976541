import React from "react";
import ToolsData from "../data/Tools.json";
import { Link } from "react-router-dom";

const Tools = (props) => {
  let toolsData = ToolsData;

  return (
    <section className="main-section learn">
      <span className="page-header-btn">{props.dictionary.pages.tools}</span>
      <div className="tools-wrapper">
        {toolsData && toolsData.length === 0 ? (
          <div className="loader">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          ""
        )}
        {toolsData.map((tool, key) => {
          return (
            <div className="tool-card">
              <div className="tool-card-content">
                <Link className="menu-icon-wrapper" to={`/tools/${tool.slug}`}>
                  <div className="tool-icon-container">
                    {props.mode === "dark" ? (
                      <img
                      src={`assets/img/tools/${tool.icon}_white.svg`}
                      alt=""
                      className="tool-icon"
                      />
                      ) : (
                        <img
                        src={`assets/img/tools/${tool.icon}.svg`}
                        alt=""
                        className="tool-icon"
                      />
                    )}
                  </div>
                  <div className="tool-name">
                    <p>{tool.name}</p>
                  </div>
                  <div className="tool-description">
                    <p>{tool.short_desc}</p>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Tools;
