import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Layout from "./components/Layout";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Learn from "./components/Learn";
import Projects from "./components/Projects";
import Tools from "./components/Tools";
import Calculator from "./Pages/Calculator";
import QrGenerator from "./Pages/QrGenerator";
import PersonaApi from "./Pages/PersonaApi";
import QuijoteLorem from "./Pages/QuijoteLorem";
import Test from "./Pages/Test";
import { UseLanguageContext } from "./context/Languaje-context";
import AiChat from "./Pages/AiChat";

function App() {
  const {
    global: { dictionary },
    mode: { mode }
  } = UseLanguageContext();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home dictionary={dictionary} mode={mode} />} />
        <Route path="/about" element={<About dictionary={dictionary} mode={mode} />} />
        <Route
          path="/portfolio"
          element={<Projects dictionary={dictionary} />}
        />
        <Route path="/learn" element={<Learn dictionary={dictionary} />} />
        <Route path="/tools" element={<Tools dictionary={dictionary} mode={mode} />} />
        {/* TOOLS */}
        <Route path="/tools/calculator" element={<Calculator />} />
        <Route path="/tools/qr_generator" element={<QrGenerator />} />
        <Route path="/tools/quijote_lorem" element={<QuijoteLorem />} />
        <Route path="/tools/persona_api" element={<PersonaApi />} />
        <Route path="/tools/ai_chat" element={<AiChat />} />
        {/* TOOLS */}
        <Route path="/test" element={<Test />} />
        <Route path="/contact" element={<Contact dictionary={dictionary} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
