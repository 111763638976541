import React from "react";

const Modal = (props) => {
  const projectData = props.object;
  const modal = document.getElementById("myModal");
  const closeModal = () => {
    modal.style.display = "none";
  };
  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  };

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <br></br>
        <article className="card-body">
          <div className="card-body-main">
            <img
              src={projectData.image}
              alt={`Imagen del proyecto ${projectData.title}`}
              className="card-image"
            />
            <div className="card-text">
              <div className="tags-container">
                  {projectData.tags?.map((tag, index) => (
                    <span className="before"><span className="tag">{tag}</span></span>
                    ))}
              </div>
              {/* <p className='card-name-date m-top-1'>by JJMDev / {projectData.date}</p> */}
              <h2 className="card-header m-top-1s">{projectData.title}</h2>
              {/* <p className='card-text-content m-top-1'>{ projectData.description }</p> */}
              <p className="card-text-content m-top-1">
                {props.object.description?.map((res) => (
                  <>{res[props.descriptionLanguage]}</>
                ))}
              </p>
              <h3>Sitio en Producción:</h3>
              <p>
                <a href={projectData.web} className="link" target="_blank">
                  {projectData.web}
                </a>
              </p>
            </div>
          </div>
          {/* <section className="image-grid">
            {projectData.extraImages?.map((image, index) => (
              <img
                src={image}
                alt={`Imagen del proyecto ${projectData.title}`}
                className="card-image"
              />
            ))}
          </section> */}
        </article>
      </div>
    </div>
  );
};

export default Modal;
