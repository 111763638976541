import { createContext, useState, useContext, useEffect } from "react";
import { useAxiosFetch } from "../custom-hooks/useAxiosFetch";
import { languageOptions, dictionaryList } from "../languages/index";
import Dictionary from "../data/Dictionary.json";

// create the language context with default selected language
const LanguageContext = createContext(undefined);

// useContext Hook
export const UseLanguageContext = () => {
  return useContext(LanguageContext);
};

// it provides the language context to app
export const LanguageContextProvider = (props) => {
  const [dictionary, setDictionary] = useState(Dictionary.es)
  const [userLanguage, setUserLanguage] = useState("ES");
  const globalValue = "Global Values";
  const { data, loading, error, fetchData } = useAxiosFetch({
    method: "GET",
    url: "/",
  });

  // Assign React state and constants to context object
  const [selectedLanguage, setSelectedLanguage] = useState("ES");
  const [mode, setMode] = useState(localStorage.getItem("theme-color") ? localStorage.getItem("theme-color") : 'light');
  const LanguageContextObject = {
    global: {
      globalValue,
      dictionary,
      setDictionary
    },
    data: {
      data,
    },
    language: {
      selectedLanguage,
      setSelectedLanguage,
    },
    mode: {
      mode,
      setMode,
    },
  };

  return (
    <LanguageContext.Provider value={LanguageContextObject}>
      {props.children}
    </LanguageContext.Provider>
  );
};
