import React from 'react'
import Mailer from './Mailer'

const Contact = (props) => {
  return (
    <section className='main-section contact'>
      <span className='page-header-btn'>
      {props.dictionary.pages.contact}
      </span>
      <h3 className='page-subheader'>{props.dictionary.contact.title}</h3>
      <Mailer form={props.dictionary.contact.form}/>
    </section>
  )
}

export default Contact