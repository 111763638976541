import React, { useEffect, useState } from "react";
import { useAxiosFetch } from "../custom-hooks/useAxiosFetch";
import Card from "./Card";
import Modal from "./Modal";
import { UseLanguageContext } from "../context/Languaje-context";

const Projects = (props) => {
  const {
    global: { dictionary },
    language: { selectedLanguage },
  } = UseLanguageContext();
  const [projects, setProjects] = useState([]);
  const [currProject, setCurrProject] = useState("");
  const [tag, setTag] = useState("All");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [dateLanguage, setDateLanguage] = useState("date_es");
  const [descriptionLanguage, setDescriptionLanguage] =
    useState("description_es");

  const extraImg = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpJHYtRe7St-otPZQYvas6fkU3Vq8vdQSopQ&usqp=CAU",
    "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/897061/pexels-photo-897061.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/4056535/pexels-photo-4056535.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];

  const { data, loading, error } = useAxiosFetch({
    method: "GET",
    url: "/",
  });

  useEffect(() => {
    if (data) {
      // console.log('DATA: ', data)
      // console.log('DATA PROJECTS: ', data.projects)
      setProjects(data.projects);
      setFilteredProjects(data.projects);
    } else {
      setProjects([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      console.log("...Loading");
    }
  }, [loading]);

  // Filtering on Tag selection
  useEffect(() => {
    let filtered = [];
    if (tag !== "All") {
      //console.log("Projects :", projects);
      for (let p of Object.values(projects)) {
        for (let t of p.tags) {
          if (t.name === tag) {
            filtered.push(p);
          }
          // console.log(p);
          // console.log(t.name);
        }
      }
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [tag]);

  useEffect(() => {
    let lowerValue = selectedLanguage.toLowerCase();
    switch (lowerValue) {
      case "es":
        setDescriptionLanguage("description_es");
        setDateLanguage("date_es");
        break;
      case "fr":
        setDescriptionLanguage("description_fr");
        setDateLanguage("date_fr");
        break;
      case "en":
        setDescriptionLanguage("description_en");
        setDateLanguage("date_en");
        break;
      default:
        break;
    }
  }, [selectedLanguage]);

  const onClickOnTag = (e) => {
    if (e.target.id === "All") {
      setTag(e.target.id);
    } else {
      setTag(e.target.innerText);
    }
  };

  const handleProjectBtnClick = (objectData) => {
    setCurrProject(objectData);
    const modal = document.getElementById("myModal");
    modal.style.display = "block";
  };

  const getTagName = () => {
    if (tag === "All") {
      return dictionary.all;
    }
    return tag;
  };

  return (
    <>
      <section className="main-section portfolio">
        <Modal
          object={currProject}
          dateLanguage={dateLanguage}
          descriptionLanguage={descriptionLanguage}
        />
        <span className="page-header-btn">
          {props.dictionary.pages.portfolio} - {getTagName()} (
          {filteredProjects ? Object.keys(filteredProjects).length : "0"})
        </span>
        <h3 className="page-subheader">{dictionary.projects_title}</h3>
        <ul className="portfolio-filter">
          <li onClick={onClickOnTag} id="All">
            {dictionary.all}
          </li>
          <li onClick={onClickOnTag}>Wordpress</li>
          <li onClick={onClickOnTag}>JavaScript</li>
          <li onClick={onClickOnTag}>React JS</li>
          <li onClick={onClickOnTag}>PHP / Symfony</li>
          <li onClick={onClickOnTag}>HTML / CSS</li>
        </ul>

        <div className="cards-wrapper">
          {filteredProjects && filteredProjects.length === 0 ? (
            <div className="loader">
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            ""
          )}
          {filteredProjects &&
            Object.entries(filteredProjects).map(([index, project], key) => (
              <>
                <Card
                  key={key}
                  index={index}
                  image={project.image}
                  title={project.title}
                  web={project.web}
                  tags={project.tags}
                  date={project.languages}
                  description={project.languages}
                  handleButton={handleProjectBtnClick}
                  extraImages={extraImg}
                  dateLanguage={dateLanguage}
                  descriptionLanguage={descriptionLanguage}
                />
              </>
            ))}
        </div>
      </section>
    </>
  );
};

export default Projects;
