import React from 'react'

const NotFound = () => {
  return (
    <section className='main-section'>
      <h1 className='page-header'>
        Page Not Found
      </h1>
    </section>
  )
}

export default NotFound