import React from "react";
import ArrowIcon from "./ArrowIcon";

const About = (props) => {
  return (
    <section className="main-section about">
      <span className="page-header-btn">{props.dictionary.pages.about}</span>
      <div className="about-image">
        {props.mode === "dark" ? (
          <img
            src={`assets/img/Illustration_dark.svg`}
            alt=""
            className="about-avatar"
          />
        ) : (
          <img
            src={`assets/img/Illustration_light.svg`}
            alt=""
            className="about-avatar"
          />
        )}
      </div>
      <div className="about_title">
        <h3 className="title">Juan José Mora Sánchez</h3>
        <p className="name-subtitle">{props.dictionary.about.subtitle}</p>
      </div>
      <div className="about_description">
        <p>{props.dictionary.about.description}</p>
      </div>

      <div className="about_skills">
        <div className="">
          <div className="">
            <div className="">
              <div>
                <h3 className="title">{props.dictionary.about.knowledge}</h3>
              </div>
              <div className="about_skill_list">
                <ul>
                  <li>
                    <span>
                      <ArrowIcon mode={props.mode} />
                      HTML 5, CSS, Bootstrap, SASS
                    </span>
                  </li>
                  <li>
                    <span>
                      <ArrowIcon mode={props.mode} />
                      JavaSript, React JS
                    </span>
                  </li>
                  <li>
                    <span>
                      <ArrowIcon mode={props.mode} />
                      PHP, Symfony
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
