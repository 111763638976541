import React from "react";

const ArrowIcon = (props) => {
  return props.mode === "dark" ? (
    <img
      className="arrow-svg"
      src="assets/img/rightarrow_white.svg"
      alt="arrow"
    />
  ) : (
    <img className="arrow-svg" src="assets/img/rightarrow.svg" alt="arrow" />
  );
};

export default ArrowIcon;
