import React, { useEffect, useState } from 'react'
import axios from 'axios'

//axios.defaults.baseURL = 'http://localhost:8000/data'
axios.defaults.baseURL = 'https://cms.jjmdev.xyz/data'

export const useAxiosFetch = (axiosParams) => {
  const [ data, setData ] = useState(undefined)
  const [ error, setError ] = useState("")
  const [ loading, setLoading ] = useState(true)

  const fetchData = async () => {
    try {
      const response = await axios.request(axiosParams)
      setData(response.data)
      //console.log('DATA: ', data)
    } catch (error) {
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect( () => {
    fetchData()
  }, [])

  return { data, error, loading, fetchData }
}