import React from 'react'
import ComingSoon from '../components/ComingSoon'

const PersonaApi = () => {
  return (
    <section className='main-section'>
      <span className='page-header-btn'>
        Persona API
      </span>
      <ComingSoon />
    </section>
  )
}

export default PersonaApi