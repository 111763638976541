import React, { useState, useEffect } from "react";
import { UseLanguageContext } from "../context/Languaje-context";

const Card = (props) => {
  const {
    global: { dictionary }
  } = UseLanguageContext();
  const tagsArray = [];

  const setTagsArray = () => {
    props.tags?.forEach((element) => {
      tagsArray.push(element.name);
    });
    return tagsArray;
  };
  const projectObject = {
    index: props.index,
    title: props.title,
    image: props.image,
    web: props.web,
    date: props.date,
    tags: setTagsArray(),
    description: props.description,
    extraImages: props.extraImages,
  };

  return (
    <article className="card-body">
      <img src={props.image} alt="" className="card-image" />
      <div className="card-text">
        <p className="card-name-date m-top-1">
          {props.description?.map((res, index) => (
            <span key={index}>{res[props.dateLanguage]}</span>
          ))}
        </p>
        <h2 className="card-header m-top-1s">{props.title}</h2>
        <small>
          {props.tags.map((tag, index) => (
            <span key={index} className="card-tag-name">
              {tag.name}
            </span>
          ))}
        </small>
        <p className="card-text-content m-top-1">
          {props.description?.map((res) => (
            <>{res[props.descriptionLanguage]}</>
          ))}
        </p>
        <div className="card-btn">
          <a
            className="card-btn-link"
            alt="#"
            onClick={() => props.handleButton(projectObject)}
          >
            <span className="card-btn-txt">{dictionary.card_btn}</span>
            <img
              src="https://www.svgrepo.com/show/121107/arrow.svg"
              alt="arrow"
              className="button-icon"
            />
          </a>
        </div>
      </div>
    </article>
  );
};

export default Card;
