import React, { useEffect, useState } from "react";
import { UseLanguageContext } from "../context/Languaje-context";

const DarkModeIcon = () => {
  const [isDark, setIsDark] = useState(false);
  const {
    mode: { setMode },
  } = UseLanguageContext();

  useEffect(() => {
    const saved = localStorage.getItem("theme-color");
    if (saved === "dark") {
      document.querySelector("body").classList.add("dark");
      document.querySelector("body").classList.remove("light");
      setIsDark(true);
    } else {
      document.querySelector("body").classList.add("light");
      document.querySelector("body").classList.remove("dark");
      setIsDark(false);
    }
  }, [isDark]);

  const handleThemeIconClick = (e) => {
    e.preventDefault();
    setIsDark(!isDark);
    if (isDark) {
      localStorage.setItem("theme-color", "light");
      setMode("light");
    } else {
      localStorage.setItem("theme-color", "dark");
      setMode("dark");
    }
  };

  return (
    <>
      {isDark ? (
        <img
          src={require("../assets/dm-icons/sun-svgrepo-com.svg").default}
          alt="DM light icon"
          className="theme-icon"
          onClick={handleThemeIconClick}
        />
      ) : (
        <img
          src={require("../assets/dm-icons/moon-svgrepo-com.svg").default}
          alt="DM dark icon"
          className="theme-icon"
          onClick={handleThemeIconClick}
        />
      )}
    </>
  );
};

export default DarkModeIcon;
