import React from "react";

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <h2>Coming Soon</h2>
      <div className="snippet" data-title="dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
