import React, { useState, useEffect } from 'react'
import { useAxiosFetch } from '../custom-hooks/useAxiosFetch'

const Test = () => {
  const [ projects, setProjects ] = useState([])
  const { data, loading, error, fetchData } = useAxiosFetch({
    method: 'GET',
    url: '/',
  })
  useEffect( () => {
    if(data){
      console.log('DATA: ', data)
      console.log('DATA PROJECTS: ', data.projects)
      setProjects(data.projects)
    } else {
      setProjects([])
    }
  }, [data])

  return (
    <section className='main-section'>
      <span className='page-header-btn'>
        Test PAGE
      </span>
      <div>
        {/* {
          JSON.stringify(projects)
        } */}
        {
            Object.entries(projects).map( ([key, project], index) => (
              <div key={index}>
                <p>{project.id}</p>
                <p>{project.title}</p>
                <p>{project.image}</p>
                <p>{project.description}</p>
                <p>{project.web}</p>
                <p>{ JSON.stringify(project.tags)}</p>
                <p>
                  { JSON.stringify(
                    project.languages.find( (l) => l.en)
                  )}
                </p>
                <hr />
              </div>
            ))
        }
      </div>
    </section>
  )
}

export default Test