import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react"; //Deprecated
import { QRCodeSVG } from "qrcode.react";
import { QRCodeCanvas } from "qrcode.react";
import ToolsData from "../data/Tools.json";

const QrGenerator = () => {
  let toolsData = ToolsData;
  const [data, setData] = useState("");
  const [url, setUrl] = useState(undefined);

  useEffect(() => {
    setData(toolsData.find((el) => el.slug === "qr_generator"));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <section className="main-section tool-page">
      <span className="page-header-btn">QR Generator</span>
      <article className="card-body">
        <div className="card-text">
          <h2 className="card-header m-top-1s">{data.name}</h2>
          <p className="card-name-date m-top-1">{data.long_desc}</p>
        </div>
        <form onSubmit={handleSubmit}>
          <label>URL: </label>
          <input
            type="text"
            className="qr-input"
            value={url}
            placeholder="Write the value here "
            onChange={(e) => setUrl(e.target.value)}
          />
        </form>
        {url && (
          <div className="qr-codes-wrapper">
            {/* <div>
              <p>PNG:</p>
              <QRCode value={url} size={256} />
            </div>
            <div>
              <p>SVG:</p>
              <QRCodeSVG value={url} size={256} />
            </div> */}
            <div>
              {/* <p>Canvas:</p> */}
              <QRCodeCanvas value={url} size={256} />
            </div>
          </div>
        )}
      </article>
    </section>
  );
};

export default QrGenerator;
