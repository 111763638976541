import React from 'react'
import LogoMenu from './LogoMenu'

const NavMenu = () => {
  return (
    <nav className='nav-bar'>
      <LogoMenu />
    </nav>
  )
}

export default NavMenu